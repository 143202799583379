import { ICMEnvironment } from 'content-manager/src/environments/environment.model';

import { environment as base } from './environment.base';

export const environment: ICMEnvironment = {
    ...base,
    production: true,
    portal: 'academicoa',
    apiUrl: 'https://api.academicoa.com',
    publicBaseUrl: 'https://www.academicoa.com',
    mainBaseUrl: 'https://main.academicoa.com',
    internalBaseUrl: 'https://admin.academicoa.com',
    identityProviderUrl: 'https://idp.academicoa.com',
    editingToolBaseUrl: '',
    contentManagerBaseUrl: '',
    promotionBaseUrl: '',
    apiCmUrl: 'https://cm.academicoa.com'
};
